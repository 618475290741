import React from "react";
import photo from "../images/photo.jpg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
export default function APropos(props: { lang: string }) {
  const lang = props.lang;

  return (
    <>
    <svg className="w-full h-full fill-darkPrimary" viewBox="0 0 1440 208" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_308_95)">
<path d="M0 0H26.7C53.3 0 107 0 160 17.6053C213.3 34.9469 267 70.5531 320 94.95C373.3 119.347 427 133.853 480 119.545C533.3 105.5 587 63.3 640 42.2C693.3 21.1 747 21.1 800 35.1447C853.3 49.4531 907 77.1469 960 91.4553C1013.3 105.5 1067 105.5 1120 94.95C1173.3 84.4 1227 63.3 1280 63.3C1333.3 63.3 1387 84.4 1413 94.95L1440 105.5V211H1413.3C1386.7 211 1333 211 1280 211C1226.7 211 1173 211 1120 211C1066.7 211 1013 211 960 211C906.7 211 853 211 800 211C746.7 211 693 211 640 211C586.7 211 533 211 480 211C426.7 211 373 211 320 211C266.7 211 213 211 160 211C106.7 211 53 211 27 211H0V0Z"/>
</g>
<defs>
<clipPath id="clip0_308_95">
<rect width="1440" height="209" fill="white"/>
</clipPath>
</defs>
</svg>
      <section
        id="a-propos"
        className="relative bg-darkPrimary"
      >
        <div className="flex flex-col md:flex-row realtive w-4/5 mx-auto">
          <div className="w-full lg:w-2/3 pt-2 pb-24 md:pr-24 text-left md:text-justify text-light">
            <h2 className="text-3xl font-bold pb-12 md:text-left text-center ">
             {lang === "FR" ? "A propos" : "About me"}
            </h2>
            <p className="text-lg mt-4">
              {
                lang === "FR"
                  ? `
                  💻 Passionné par le développement et l'univers de l'informatique, je me consacre à la réalisation de nombreux projets.
                  
                  `
                  : `💻 Passionate about development and the world of computer science, I dedicate myself to the realization of numerous projects.`
              }
              
            </p>

            <p className="text-lg mt-8">
              
              {
                lang === "FR"
                  ? `
                  👨‍🎓 Actuellement étudiant en apprentissage à TELECOM Nancy et diplômé de l’IUT Nancy Charlemagne, j'ai consolidé mes compétences à travers ces formations.      
                  `
                  : `👨‍🎓 Currently an apprentice student at TELECOM Nancy and a graduate of IUT Nancy Charlemagne, I have consolidated my skills through these training courses.`
              }
            </p>

            <p className="text-lg mt-8">
              
              {
                lang === "FR"
                  ? `
                  💼 J’effectue mon apprentissage chez Thém-is, où je mets en pratique mes compétences tout en enrichissant mon savoir-faire au quotidien.
                  
                  `
                  : `💼 I am doing my apprenticeship at Thém-is, where I apply my skills while enriching my expertise daily.`
              }
            </p>

            <p className="text-lg mt-8">
            {
                lang === "FR"
                  ? `
                  🌟 Depuis toujours, je m'engage dans de nombreux projets personnels pour m'amuser et affiner mes compétences pratiques.
                  
                  `
                  : `🌟 For as long as I can remember, I have been engaged in numerous personal projects for fun and to refine my practical skills.`
              }
              
            </p>

            <p className="text-lg mt-8">
            {
                lang === "FR"
                  ? `
                  📈 En parallèle de mes études et de mon apprentissage, j'ai lancé ma micro-entreprise dans le domaine de la programmation informatique, explorant ainsi divers domaines passionnants.
                  
                  `
                  : `📈 Alongside my studies and apprenticeship, I launched my micro-enterprise in the field of computer programming, thus exploring various exciting domains.`
              }
              
            </p>
          </div>

          <div className="w-full lg:w-1/3 text-justify text-light flex flex-col justify-center pb-24 md:pb-0">
            <div className="flex flex-col flex-wrap">
              <a href="mailto:contact@adrienscher.fr" className=" hover:text-dark p-2 justify-between bg-darkSecondary rounded-md flex flex-row items-center w-full mb-8 hover:before:bg-darkThird relative overflow-hidden transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0  before:bg-darkThird before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full">
                <div className="flex items-center">
                <EmailIcon className="mr-4 z-20" fontSize="large" />
                <div className="z-20">
                  <p>contact@adrienscher.fr</p>
                </div>
                </div>
                <ChevronRightIcon className="ml-4 z-30" fontSize="large" />
              </a>



              <a target="_blank" href="https://www.linkedin.com/in/adrien-scher-31220b278/" className="hover:text-dark p-2 justify-between bg-darkSecondary rounded-md flex flex-row items-center w-full mb-8 hover:before:bg-darkThird relative overflow-hidden transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0  before:bg-darkThird before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full">
                <div className="flex items-center">
                <LinkedInIcon className="mr-4 z-20" fontSize="large" />
                <div className="z-20">
                  <p>Adrien Scher</p>
                </div>
                </div>
                <ChevronRightIcon className="ml-4 z-30" fontSize="large" />
              </a>


              <a target="_blank" href="https://github.com/adrien99920" className="hover:text-dark p-2 bg-darkSecondary rounded-md flex flex-row justify-between items-center w-full mb-8 hover:before:bg-darkThird relative overflow-hidden transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-darkThird before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full">
                <div className="flex items-center"> 
                <GitHubIcon className="mr-4 z-20" fontSize="large" />
                <div className="z-20">
                  <p>Adrien99920</p>
                </div>
                </div>
                <ChevronRightIcon className="ml-4 z-30" fontSize="large" />
              </a>

              <a target="_blank" href="https://www.google.fr/maps/place/Grand+Est/@48.7645407,3.1637434,7z/data=!3m1!4b1!4m6!3m5!1s0x479155dfc58fb5b1:0xb9c8197dcfd117fd!8m2!3d48.9131152!4d5.4425501!16s%2Fg%2F11b6rq_x_8?entry=ttu&g_ep=EgoyMDI0MTAyMy4wIKXMDSoASAFQAw%3D%3D" className=" hover:text-dark p-2  justify-between bg-darkSecondary rounded-md flex flex-row items-center w-full mb-8  hover:before:bg-darkThird relative overflow-hidden transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-darkThird before:transition-all before:duration-500  hover:before:left-0 hover:before:w-full">
                <div className="flex items-center">
                <LocationOnIcon className=" mr-4 z-20" fontSize="large" />
                <div className="z-20">
                  <p>Grand Est, France</p>
                </div>
                </div>
                <ChevronRightIcon className="ml-4 z-30" fontSize="large" />
              </a>

              
            </div>
          </div>
        </div>
      </section>
      <div className="custom-shape-divider-top-1715358107">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          className="fill-darkPrimary"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          ></path>
        </svg>
      </div>
    </>
  );
}
