import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import MainPage from "./MainPage";

export default function Router() {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("adrienCookiesAccepted");
    if (hasAccepted && hasAccepted === "true") {
      setAccepted(true);
    }else{
      setAccepted(false);
    }
  }, []);

  useEffect(() => {
    if (accepted) {
      ReactGA.initialize("G-1MBNHYM967");
    }
  }, [accepted]);

  const handleAccept = () => {
    localStorage.setItem("adrienCookiesAccepted", "true");
    setAccepted(true);
  };
  const sendPageView = (page, title) => {
    if (accepted) {
      ReactGA.send({ hitType: "pageview", page, title });
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage sendPageView={sendPageView}
                accepted={accepted}
                handleAccept={handleAccept} />}></Route>
            <Route path="/project/:projectId" element={<MainPage sendPageView={sendPageView}
                accepted={accepted}
                handleAccept={handleAccept}/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
