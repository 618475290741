import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LangageToggle from "../components/LangageToggle";
import { useParams } from "react-router-dom";
import ProjectDetail from "../projects/ProjectDetail";
import CookieConsent from "../components/CookieConsent";

export default function MainPage(props: {
  sendPageView: any;
  accepted: any;
  handleAccept: any;
}) {
  const { sendPageView, accepted, handleAccept } = props;
  const [lang, setLang] = React.useState("EN");
  const { projectId } = useParams();

  useEffect(() => {
      sendPageView("/accueil", "Accueil");
  }, []);
  return (
    <>
      <LangageToggle setLang={setLang} lang={lang} />
      {!accepted && <CookieConsent setAccepted={handleAccept} lang={lang} />}

      <div className="bg-dark">
        {projectId ? (
          <ProjectDetail
            projectId={projectId}
            lang={lang}
            sendPageView={sendPageView}
          />
        ) : (
          <NavBar lang={lang} />
        )}
        <Footer lang={lang} />
      </div>
    </>
  );
}
