import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { sections } from "../contents/content";
import React from "react";
import Section from "../sections/Section";

const Navbar = (props: {lang: string}) => {
  const lang = props.lang;
  const [activeSection, setActiveSection] = useState("accueil");
  const [timeOfLastClick, setTimeOfLastClick] = useState(0);
  const [isOpen, setIsOpen] = useState(false); 
  
  useEffect(() => {
    // Extrait la section ancrée depuis l'URL
    const section = window.location.hash.substring(1);
    if (section) {
      setActiveSection(section);
      // Force le scroll vers l'élément après un court délai pour garantir que tout est bien monté
      setTimeout(() => {
        const element = document.getElementById(section);
        element?.scrollIntoView({ behavior: "instant" });
      }, 0); // Attente de 300ms pour s'assurer que le DOM est bien monté
    }
  }, []);

  return (
    <>
      <nav className="fixed z-40 md:top-4 left-1/2 -translate-x-1/2 px-3 py-2 md:rounded-full md:w-auto w-full bg-darkPrimary backdrop-blur-lg">
        <div className="hidden md:flex justify-center items-center gap-2 font-bold text-light">
          <ul className="flex">
            {sections.map((link) => (
              <div key={link.id}>
                <a
                  href={`#${link.id}`}
                  onClick={() => {
                    setTimeOfLastClick(Date.now());
                    setActiveSection(link.id);
                  }}
                  className={`relative text-sm py-2 px-4 inline-block whitespace-nowrap ${activeSection === link.id ? "text-black" : "text-light"
                    }`}
                >
                  {lang === "FR" ? link.titre : link.title}
                  {activeSection === link.id && (
                    <motion.div
                      layoutId="bubble"
                      className="absolute inset-0 -z-10 bg-darkThird rounded-full"
                      transition={{ type: "spring", duration: 0.6 }}
                    ></motion.div>
                  )}
                </a>
              </div>
            ))}
          </ul>
        </div>
        <div className="md:hidden flex justify-center items-center">
          <button onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? <MenuIcon className="h-6 w-6 text-light" /> : null}
          </button>
          {isOpen && (
            <ul className="flex flex-col items-center gap-2">
              {sections.map((link) => (
                <li key={link.id}>
                  <a
                    href={`#${link.id}`}
                    onClick={() => {
                      setIsOpen(false);
                      setTimeOfLastClick(Date.now());
                      setActiveSection(link.id);
                    }}
                    className={`relative text-sm py-2 px-4 inline-block ${activeSection === link.id ? "text-darkThird" : "text-light"
                      }`}
                  >
                    {lang === "FR" ? link.titre : link.title}
                    {activeSection === link.id && (
                      <motion.div
                        layoutId="bubble"
                        className="absolute inset-0 -z-10 bg-brun rounded-full"
                        transition={{ type: "spring", duration: 0.6 }}
                      ></motion.div>
                    )}
                  </a>
                </li>
              ))}
              <button onClick={() => setIsOpen(!isOpen)}>
                <li><CloseIcon className="h-6 w-6 text-light" /></li>
              </button>
            </ul>
          )}
        </div>
      </nav>

      {sections.map((section) => (
        <Section
          key={section.id}
          setActiveSection={setActiveSection}
          section={section}
          timeOfLastClick={timeOfLastClick}
          lang={props.lang}
        />
      ))}
    </>
  );
};

export default Navbar;
