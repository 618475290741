import React from "react";
import { projects } from "../contents/projects.ts";
import CircleHoverButton from "../components/CircleHoverButton.tsx";
import { useNavigate } from "react-router-dom";

export default function Projects(props: { lang: string }) {
  const lang = props.lang;
  const navigate = useNavigate();

  return (
    <div id="projets" className="w-full px-4 md:w-4/5 mx-auto pt-12">
      <h2 className="text-light text-center text-3xl font-bold pt-8 pb-2">
        {lang === "FR" ? "Mes projets personnels" : "My personal projects"}
      </h2>
      <p className="text-lg text-light pb-8 text-center">
        {lang === "FR"
          ? `En parallèle de mes études et de mes expériences professionnelles, je réalise de nombreux projets personnels. Voici quelques-uns d’entre eux.`
          : `Alongside my studies and professional experiences, I undertake numerous personal projects. Here are a few of them.`}
      </p>

        <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-12 ">
          {projects.map((project, index) => (
              <div
              key={index}
                className="cursor-pointer transition-all	duration-500 border border-darkPrimary border-b-darkThird hover:border-darkThird rounded-lg shadow-none bg-darkPrimary"
                onClick={() => navigate(`/project/${project.id}`)}                
              >
                <div className="w-full h-36 rounded-t-lg">
                  <img
                    src={project.image}
                    className="object-bottom xl:object-bottom object-cover w-full h-full rounded-t-lg"
                    alt={`image du projet ${project.titre} fait par Adrien SCHER`}
                  />
                </div>
                <div className="text-light p-4 flex flex-col">
                  
                  <h3 className="text-lg font-bold text-left mb-2">
                    {project.titre}
                  </h3>
                  <p className="text-base text-left min-h-24">
                    {lang === "FR"
                      ? project.shortDescription.fr
                      : project.shortDescription.en}
                  </p>

                  <CircleHoverButton
                    href={`/project/${project.id}`}
                    className="rounded-md w-fit md:mx-0  hover:text-black"
                    texte={`${lang === "FR" ? "En savoir plus" : "Learn more"}`}
                    textColor={"text-light"}
                    bgColor={"bg-darkSecondary"}
                    beforBgColor={"before:bg-darkThird"}
                    w={"w-5/12 lg:w-fit"}
                    px="px-4"
                    py="py-1"
                  />
                </div>
              </div>
          
          ))}
        </div>
    </div>
  );
}
