import React from "react";
import englishLangage from "../images/English_language.svg";
import frenchLangage from "../images/Flag_of_France.svg";
export default function LangageToggle(props: {setLang: (lang: string) => void, lang: string}){
	const currentLang = props.lang;
	const LangageToggle = () => {
		if (currentLang === "FR") {
			props.setLang("EN");
		}
		else {
			props.setLang("FR");
		}
	}


    return (
		<button
      onClick={LangageToggle}
      className="h-12 w-12 rounded-lg p-2 hover:bg-gray-700 fixed md:top-2 left-2 z-50"
    >
        <img src={frenchLangage} className={`${currentLang === "FR" ? "hidden" : "block"}`} alt="" />

        <img src={englishLangage} className={`${currentLang === "FR" ? "block" : "hidden"}`} alt="" />
    </button>
    )
}