import React from "react";
import ExperienceTabV2 from "../components/ExperienceTabV2";

export default function Experience(props: {lang: string}){
    const lang = props.lang;

    return (
        <section id="experiences" className="w-full pt-24 z-30 flex flex-row">
            <div  className="w-full md:w-4/5 mx-auto z-30">
                <h2 className="text-3xl font-bold pb-12 text-light text-center">
                    {lang === "FR" ? "Mes expériences" : "My experiences"}
                </h2>
                <ExperienceTabV2 lang={lang} />
            </div>
        </section>
    )
}