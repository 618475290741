import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
interface ExperienceContent {
  id: number;
  titre: { fr: string; en: string };
  date: { fr: string; en: string };
  entite: string;
  description: { fr: string; en: string };
  competences: { fr: string; en: string }[];
}
const content = [
  {
    id: 0,
    titre: { fr: "Auto-entrepreneur", en: "Self-employed" },
    date: { fr: "Avril 2024 - Présent", en: "April 2024 - Present" },
    entite: { fr: "Adrien Scher", en: "Adrien Scher" },
    description: {
      fr: `
- Gestion du temps pour respecter les délais des projets et organisation de réunions avec les clients
- Conception et développement de sites web personnalisés adaptés aux besoins des clients
- Analyse des besoins clients et proposition de solutions techniques
- Optimisation du référencement naturel (SEO), gestion des pages Google My Business et amélioration de la visibilité en ligne des clients
- Prospection de nouveaux clients et maintien de bonnes relations avec eux
`,
      en: `
- Design and develop custom websites tailored to client needs
- Enhance SEO, manage Google My Business pages, and improve clients' online visibility
- Analyze customer requirements and provide technical solutions
- Manage time effectively to meet project deadlines and organize meetings with clients
- Prospect for new clients and maintain strong client relationships
`,
    },
    competences: [
      { fr: "SEO", en: "SEO" },
      { fr: "Développement web", en: "Web development" },
      { fr: "Figma", en: "Figma" },
      { fr: "Relation client", en: "Client relationship" },
      { fr: "Gestion de projet", en: "Project management" },
      { fr: "Organisation", en: "Organization" },
    ],
  },
  {
    id: 1,
    titre: { fr: "Consultant en informatique", en: "IT consultant" },
    date: { fr: "Septembre 2023 - Présent", en: "September 2023 - Present" },
    entite: { fr: "Thém-is - alternance", en: "Thém-is - apprenticeship" },
    description: {
      fr: `
- Développement d'applications web sur SharePoint en utilisant SPFx et React.js
- Automatisation des workflows avec Power Automate
- Recherche et développement pour explorer Next.js dans le but d'améliorer les solutions de l'entreprise
- Gestion complète d'un projet interne, de l'analyse à la mise en œuvre, en toute autonomie
`,
      en: `
- Build SharePoint web applications using SPFx and React.js
- Automate workflows with Power Automate
- Conducted research and development to explore Next.js for improving company solutions
- Managed a full internal project independently, from analysis to implementation

`,
    },
    competences: [
      { fr: "React.js", en: "React.js" },
      { fr: "Next.js", en: "Next.js" },
      { fr: ".NET", en: ".NET" },
      { fr: "C#", en: "C#" },
      { fr: "SharePoint", en: "SharePoint" },
      { fr: "Power Automate", en: "Power Automate" },
      { fr: "Gestion de projet", en: "Project management" },
      { fr: "Autonomie", en: "Autonomy" },
    ],
  },
  {
    id: 2,
    titre: { fr: "Informaticien ", en: "Computer scientist" },
    date: { fr: "Été 2023", en: "Summer 2023" },
    entite: { fr: "FM Logistic", en: "FM Logistic" },
    description: {
      fr: `
- Développement d'un outil de prédiction basé sur le machine learning en utilisant BigQuery ML sur la Google Cloud Platform pour optimiser la logistique des entrepôts
`,
      en: `
- Developed a machine learning prediction tool using BigQuery ML on Google Cloud Platform to optimize warehouse logistics
`,
    },
    competences: [
      { fr: "Google Cloud Platform", en: "Google Cloud Platform" },
      { fr: "BigQuery ML", en: "BigQuery ML" },
      { fr: "Analyse de données", en: "Data analysis" },
    ],
  },
  {
    id: 3,
    titre: { fr: "Informaticien ", en: "Computer scientist" },
    date: { fr: "Février 2023 - Avril 2023", en: "February 2023 - April 2023" },
    entite: { fr: "FM Logistic - stage", en: "FM Logistic - internship" },
    description: {
      fr: `
- Gestion de la migration d'une base de données on-premise vers Google Cloud Platform
- Analyse des données à l'aide de requêtes SQL pour répondre aux besoins métiers et faciliter la prise de décision
- Utilisation des services Google Cloud tels que BigQuery, Cloud Functions et Looker Studio pour automatiser et gérer efficacement les processus de données
`,
      en: `
- Managed the migration of an on-premise database to Google Cloud Platform
- Analyzed data using SQL queries to meet business needs and facilitate informed decision-making
- Utilized Google Cloud services like BigQuery, Cloud Functions, and Looker Studio to automate and manage data processes efficiently
`,
    },
    competences: [
      { fr: "Google Cloud Platform", en: "Google Cloud Platform" },
      { fr: "BigQuery", en: "BigQuery" },
      { fr: "Cloud Functions", en: "Cloud Functions" },
      { fr: "Python", en: "Python" },
      { fr: "Google Looker Studio", en: "Google Looker Studio" },
    ],
  },
];

export default function ExperienceTabV2(props: { lang: string }) {
  const [currentSection, setCurrentSection] = useState(0);
  const [openSection, setOpenSection] = useState<number | null>(null);
  const lang = props.lang;

  const toggleSection = (index: number) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div className="experience md:bg-darkPrimary p-4 rounded-lg">
      {/* Onglets Desktop */}
      <div className="relative hidden md:block">
        <div className="flex w-full flex-row justify-around">
          {content.map((experience, index) => (
            <h3
              key={index}
              className={`cursor-pointer pb-2 w-1/4 text-center flex items-end justify-center hover:text-darkThird text-lg ${
                currentSection === index ? "text-darkThird" : "text-white"
              }`}
              onClick={() => setCurrentSection(index)}
            >
              {lang === "FR" ? experience.titre.fr : experience.titre.en}
            </h3>
          ))}
        </div>
        <div
          className="absolute bottom-0 h-0.5 bg-darkThird transition-all duration-500"
          style={{
            width: "calc(100% / " + content.length + ")",
            transform: `translateX(${currentSection * 100}%)`,
          }}
        />
      </div>

      {/* Contenu Desktop */}
      <div className="hidden md:flex flex-row">
        {content.map((item, index) => (
          <div
            key={index}
            className={`w-full p-4 ${
              currentSection === index ? "block" : "hidden"
            }`}
          >
            <div className="p-4 bg-darkPrimary text-light">
              <h4 className="text-base font-bold">
                {lang === "FR" ? item.entite.fr : item.entite.en}
              </h4>
              <p className="text-sm text-darkThird">
                {lang === "FR" ? item.date.fr : item.date.en}
              </p>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="mt-4 list-disc list-inside"
              >
                {lang === "FR" ? item.description.fr : item.description.en}
              </ReactMarkdown>
              <div className="flex flex-row flex-wrap mt-6">
              {item.competences.map((competence, key) => (
                <div
                  key={key}
                  className="text-sm bg-darkSecondary text-light p-2 rounded-lg mr-2 mb-2  hover:text-darkThird  hover:bg-darkPrimary transition-all duration-300"
                >
                  {lang === "FR" ? competence.fr : competence.en}
                </div>
              ))}
            </div>
            </div>
          </div>
        ))}
      </div>

      {/* Menu déroulant mobile */}
      <div className="md:hidden">
        {content.map((item, index) => (
          <div key={index} className="mb-4">
            <div
              className={`cursor-pointer flex flex-col justify-between items-center bg-darkPrimary text-light p-4 rounded-lg`}
              onClick={() => toggleSection(index)}
            >
              <div className="flex justify-between w-full">
                <h3
                  className={`text-lg font-bold transition-colors duration-500 ${
                    openSection === index && "text-darkThird"
                  }`}
                >
                  {lang === "FR" ? item.titre.fr : item.titre.en}
                </h3>
                <span
                  className={`transition-transform duration-300 ${
                    openSection === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <ArrowDropDownIcon />
                </span>
              </div>

              <div
                className={`transition-all duration-500 overflow-hidden ${
                  openSection === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="p-4 bg-darkPrimary text-light rounded-b-lg">
                  <h4 className="text-base font-bold">
                    {lang === "FR" ? item.entite.fr : item.entite.en}
                  </h4>
                  <p className="text-sm text-darkThird">
                    {lang === "FR" ? item.date.fr : item.date.en}
                  </p>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="mt-4 list-disc list-inside"
                  >
                    {lang === "FR" ? item.description.fr : item.description.en}
                  </ReactMarkdown>
                  <div className="flex flex-row flex-wrap mt-6">
              {item.competences.map((competence, key) => (
                <div
                  key={key}
                  className="text-sm bg-darkSecondary text-light p-2 rounded-lg mr-2 mb-2  hover:text-darkThird hover:bg-darkPrimary transition-all duration-300"
                >
                  {lang === "FR" ? competence.fr : competence.en}
                </div>
              ))}
            </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
