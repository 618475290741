import React, { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';

export default function Section(props) {
    const { setActiveSection, timeOfLastClick, section, lang } = props;
    const { id } = section;
    const sectionRef = useRef<HTMLDivElement>(null); // Référence à la section

    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const rect = sectionRef.current.getBoundingClientRect();
                // Si le haut de la section est proche de 0 (en haut de l'écran)
                if (rect.top >= 0 && rect.top <= 100) { // On considère ici un seuil de 100px
                    // Vérifier que le dernier clic ne s'est pas produit récemment
                    if (Date.now() - timeOfLastClick > 1000) {
                        setActiveSection(id); // Mettre à jour la section active
                    }
                }
            }
        };

        // Ajoute un écouteur pour le défilement
        window.addEventListener("scroll", handleScroll);
        // Nettoie l'événement lors du démontage du composant
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [id, setActiveSection, timeOfLastClick]);

    return (
        <div ref={sectionRef} id={id}>
            <Helmet>
            <title>Adrien SCHER</title>
            <meta name="description" content="Adrien Scher - étudiant ingénieur en informatique, alternant et auto-entrepreneur."/>
            <link rel="icon" href="../../public/icon.png" />
            </Helmet>
            <section.element lang={lang} />
        </div>
    );
}
