import React, { useState } from "react";
import CircleHoverButton from "./CircleHoverButton";

export default function CookieConsent({ setAccepted, lang }) {
  const [open, setOpen] = useState(true);
  
  const handleAccept = () => {
    setAccepted(true);
    setOpen(false);
  };

  const handleRefuse = () => {
    setAccepted(false);
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div className="fixed bottom-0 z-50 flex flex-col items-center text-light justify-center p-8 bg-darkPrimary w-full">
          <h2 className="text-3xl titre font-bold mb-2">
            {lang === "FR" ? "Utilisation de Google Analytics" : "Use of Google Analytics"}
          </h2>
          <p className="text-sm mb-4">
            {lang === "FR" ? "Ce site utilise Google Analytics" : "This site uses Google Analytics"}
          </p>
          
          <div className="flex flex-row gap-x-4">
            <button 
              onClick={handleAccept}  
              className="relative flex h-fit px-4 py-4 w-fit rounded-lg before:bg-darkThird/700 bg-darkThird font-semibold text-black items-center justify-center overflow-hidden transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:duration-500 before:ease-out hover:before:h-56 hover:before:w-56"
            >
              <span className="relative z-10">
                {lang === "FR" ? "Accepter" : "Accept"}
              </span>
            </button>

            <button 
              onClick={handleRefuse}  
              className="relative flex h-fit px-4 py-4 w-fit rounded-lg before:bg-darkPrimary bg-dark text-light items-center justify-center overflow-hidden transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:duration-500 before:ease-out hover:before:h-56 hover:before:w-56"
            >
              <span className="relative z-10">
                {lang === "FR" ? "Refuser" : "Decline"}
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
