import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import StorageIcon from '@mui/icons-material/Storage';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';

const colors = [
  "#5A87DB",
  "#805ADB",
  "#5A5EDB",
  "#5AB0DB",
  "#AA5ADB",
  "#A3A5DB",
  "#755ADB",
];

const competences = [
  {
    id: 0,
    logo: <CodeIcon />,
    titre: { fr: "Développement web", en: "Web Development" },
    description: {
      fr: `
- React.js
- Next.js
- Tailwind CSS
- Wordpress
- SharePoint
- Figma
      `,
      en: `
- React.js
- Next.js
- Tailwind CSS
- Wordpress
- SharePoint
- Figma
      `,
    },
  },
  {
    id: 1,
    logo: <DataObjectIcon />,
    titre: { fr: "Développement logiciel", en: "Software Development" },
    description: {
      fr: `
- Java
- JavaFX
- Python
      `,
      en: `
- Java
- Python
- JavaFX
      `,
    },
  },
  {
    id: 2,
    logo : <StorageIcon />,
    titre: { fr: "Cloud et analyse de données", en: "Cloud & Data Analysis" },
    description: {
      fr: `
- Google Cloud Platform
- Looker Studio
- SQL
      `,
      en: `
- Google Cloud Platform
- SQL
      `,
    },
  },
  {
    id: 3,
    logo : <QueryStatsIcon />,
    titre: { fr: "SEO et visibilité en ligne", en: "SEO & Online Visibility" },
    description: {
      fr: `
- Optimisation du référencement naturel
- Gestion des pages Google My Business
      `,
      en: `
- SEO optimization
- Google My Business management
      `,
    },
  },
  {
    id: 4,
    logo : <ManageHistoryIcon />,
    titre: { fr: "Gestion de projet", en: "Project Management" },
    description: {
      fr: `
- Gestion de priorités
- Analyse de besoins
- ClickUp
- Trello
      `,
      en: `
- Prioritization
- Requirements analysis
- ClickUp
- Trello
      `,
    },
  },
  {
    id: 5,
    logo: <SelfImprovementIcon />,
    titre: { fr: "Savoir-être", en: "Soft Skills" },
    description: {
      fr: `
- Autonomie
- Organisation 
- Gestion des priorités
- Persévérance 
- Initiative
- Travail en équipe
- Communication
      `,
      en: `
- Autonomy
- Organization 
- Priority management
- Perseverance 
- Initiative
- Teamwork
- Communication
      `,
    },
  },
];

export default function Competence(props: { lang: string }) {
  const lang = props.lang;

  return (
    <section id="competences" className="w-full z-30 flex flex-row py-24">
      <div className="w-4/5 mx-auto z-30">
        <h2 className="text-light text-center text-3xl font-bold pb-12 mx-auto">
          {lang === "FR" ? "Mes compétences" : "My skills"}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-6">
          {competences.map((competence, index) => (
              <div
                key={index}
                className="relative experience text-light h-full transition-all duration-500 hover:scale-[99%] w-full p-4 mb-8 bg-darkPrimary rounded-md"
              >
                <div
                  className="rounded md absolute top-0 h-12 w-12 flex items-center justify-center left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  style={{ backgroundColor: colors[competence.id] }}
                >
                  <div
                    className="h-8 w-8 rounded-full text-black flex justify-center items-center "
                  >
                    {competence.logo}
                  </div>
                </div>

                <h3 className="text-lg text-light font-bold text-center mt-6">
                    {lang === "FR" ? competence.titre.fr : competence.titre.en}
                  </h3>

                  <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  className="mt-6 text-justify list-disc list-inside"
                >
                  {lang === "FR"
                    ? competence.description.fr
                    : competence.description.en}
                </ReactMarkdown>
              </div>
          ))}
        </div>




        

      </div>
    </section>
  );
}
